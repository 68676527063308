/** Layout **/
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  main {
    flex: 1 0 auto;

    .container {
      padding: 0 1rem;
    }
  }
}
