#mask {
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0);

  .blur & {
    z-index: 100;
    background-color: rgba(0, 0, 0, .25);
  }
}
